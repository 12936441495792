<template>

<div class="text-left no-print">
    <v-menu ref="menu" offset-x min-width="400px" min-height="300px"  :close-on-content-click="false" class="no-print">
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="description-final">{{ item.data.description }}</span>
      </template>
      <v-card class="card">
        <v-card-title class="card-title"> {{item.data.description}}  </v-card-title>
        <v-card-text class="card-sub pb-2"> {{ subTitleFormat( item.data ) }}  </v-card-text>

        <v-card-text class="card-t pb-0"> Emissão: <span> {{ item.data.dtEmission | datas('DD/MM/YYYY') }} </span>   </v-card-text>
        <hr class="ml-4 mt-1 mr-4"/>
        <v-card-text class="card-t pt-0 pb-0 mt-2"> Vencimento: <span> {{ item.data.dtDue | datas('DD/MM/YYYY') }} </span>   </v-card-text>
        <hr class="ml-4  mt-1 mr-4"/>
        <v-card-text class="card-t pt-0 pb-0 mt-2"> Pagamento: <span> {{ item.data.dtPaid | datas('DD/MM/YYYY') }} </span>   </v-card-text>
        <hr class="ml-4  mt-1 mr-4"/>
        <v-card-text class="card-t pt-0 pb-0 mt-2"> Conta: <span> {{ item.data.Accounts.name}} </span>   </v-card-text>
        <hr class="ml-4  mt-1 mr-4"/>
        <v-card-text class="card-t pt-0 pb-0 mt-2"> Categoria: <span> {{ item.data.RecordCategories.name}} </span>   </v-card-text>

        <!-- Abrir modais de lancamentos -->
        <div class="card-links mt-5">
          <div class="card-links-text ml-4">
            <span :disabled="loadingRelatorio" class="" v-if="!!item.data.dtPaid" @click.prevent="openModalRecibo(item.data)"> emitir recibo </span>
            
            <span v-if="!item.data.jsonFlags" :disabled="loadingRelatorio" class="" @click.prevent="openModal('duplicar', item.data)"> duplicar </span>

            <span :disabled="loadingRelatorio" class="" @click.prevent="openModal('editar', item.data)"> editar </span>

            <span :disabled="loadingRelatorio"  class="ex"  @click.prevent="openModalExcluir('excluir', item.data)" > excluir </span>

              <span class="card-loading" v-if="loadingRelatorio">
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  />
          </span>
          </div>

        </div>
      </v-card>
    </v-menu>
  </div>

</template>

<script>
import Events from '@/core/service/events'
import moment from 'moment'
import { mapActions } from 'vuex'
moment.locales('pt-br')
export default {
  name: 'tooltipLancamento',
  components: {
  },
  props:['item', 'id'],

  data: () => ({
    loadingRelatorio: false,
    deleted: {}
  }),
  mounted () {
    const vm = this

    Events.$on('close::modal::categoria', () => {
      vm.modal = false
      vm.Mexcluir = false
    })
  },
  computed: {
    teste () {
      let menu = this.$refs['menu']
      return menu
    }
  },
  methods: {
    ...mapActions('organizzeFinanceiroLancamentos', ['getAccounts', 'getCategories', 'getTags']),
    subTitleFormat (it) {
      let text = it.type === 'R' ? 'Receita' : 'Despesa'
      let days = moment().diff(moment(it.dtEmission), 'days')
      return `${text} lançamento há ${days ? `${days} dias` : 'pouco tempo'}`
    },

    async openModal (name, data) {
      if (data.id !== this.id) return
      const vm = this
      vm.loadingRelatorio = true
      const res = await Promise.all([
        vm.getAccounts(),
        vm.getCategories(),
        vm.getTags()
      ])
      console.log(res)
      vm.loadingRelatorio = false
      Events.$emit('categorias::edit::modal', { record: data,  name }, null)
    },

    async openModalExcluir (name, data) {
      Events.$emit('categorias::edit::modal', { record: data,  name }, null)
    },

    openModalRecibo (data) {
      Events.$emit('categorias::emitirRecibo::modal', { record: data,  name }, null)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/helpers/variables";
.card {
  &-title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 0;
  }
  &-sub {
    font-weight: normal;
    font-size: 12px;
    color: #9797ad !important;
  }
  &-t {
    color: #9797ad !important;
    span {
      color: #525252 !important;
      font-size: 14px;
    }
  }
  &-links {
    display: block;
    width: 100%;
    background: #efefef;
    padding: 15px 0;
    font-size: 14px;
    &-text{
      color: #5076ff;
      span {
        padding: 0 10px;
        cursor: pointer;
        font-size: 14px;
        &:hover{
          color: #708efc;
        }
      }
      span.ex:hover{
          color: red !important;
        
      }
    }
  }
}

</style>