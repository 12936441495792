<template>
   <div>
     <h1>{{ typeCategoriesString }} </h1>
     <v-data-table 
        class="fb-table-elem table"
        :headers="dessertHeaders"
        :items="lancamentoPorCategorias.data"
        show-expand
        hide-default-footer
        :disable-pagination="true"
      >
        <template v-slot:item.categoria="{item}">
          {{ item.lancamentos.categoriaPai.name}}
        </template>
        <template v-slot:item.percentual="{item}">
          {{ getPercentage(item.total, lancamentoPorCategorias.total) | percentage }}
        </template>
        <template v-slot:item.valor="{item}">
          <span :class="[item.total > 0 ? 'color-red' : 'color-green']">{{ item.total | currency }}</span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pr-0">
            <v-data-table 
              v-if="item.lancamentos.raiz.children.length "
              class="fb-table-elem pl-0 pr-0"
              :headers="dessertHeaders"
              :items="item.lancamentos.raiz.children"
              show-expand
              single-expand
              item-key="k"
              hide-default-header
              hide-default-footer
              :disable-pagination="true"
            >

              <template v-slot:item.categoria="{item}">
                {{ item.data[0].data.RecordCategories.name}}
              </template>
              
              <template v-slot:item.percentual="{item}">
                {{ getPercentagePai(item) | percentage }}
              </template>
              <template v-slot:item.valor="{item}">
                <span :class="[item.total > 0 ? 'color-red' : 'color-green']">{{ item.total | currency }}</span>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pr-0">
                  <v-data-table 
                    class="fb-table-elem pl-0 pr-0"
                    :headers="dessertHeaders"
                    :items="item.data"
                    hide-default-header
                    hide-default-footer
                    single-expand
                    :item-key="getPercentagePai(item)"
                    :disable-pagination="true"
                  >

                    <template v-slot:item.categoria="{item}">
                      <tooltipLancamento :item="item" :key="item.data.id" :id="item.data.id" />
                    </template>

                    <template v-slot:item.percentual="{item}">
                       {{ item.data.dtEmission | datas('DD/MM/YYYY') }} 
                    </template>
                    <template v-slot:item.valor="{item}">
                      <span  class="total-final" :class="[item.data.value > 0 ? 'color-red' : 'color-green']">{{ item.data.value | currency }}</span>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>

            <v-data-table 
              v-if="item.lancamentos.raiz.data.data.length"
              class="fb-table-elem pl-0 pr-0"
              :headers="dessertHeaders"
              :items="item.lancamentos.raiz.data.data"
              hide-default-header
              hide-default-footer
              single-expand
              :disable-pagination="true"
            >

              <template v-slot:item.categoria="{item}">
                <span class="description-final">{{ item.data.description }}</span>
              </template>

              <template v-slot:item.percentual="{item}">
                {{ item.data.dtEmission | datas('DD/MM/YYYY') }}
              </template>
              <template v-slot:item.valor="{item}">
                <span class="total-final" :class="[item.data.value < 0 ? 'color-red' : 'color-green']">{{ item.data.value | currency }}</span>
              </template>
            </v-data-table>
          </td>
        </template>

         <template slot="body.append">
          <tr class="">
            <th></th>
            <th>Total</th>
            <th>100%</th>
            <th>{{ lancamentoPorCategorias.total | currency}}</th>
          </tr>
        </template>
      </v-data-table>
   </div>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
import tooltipLancamento from './tooltipLancamento'
export default {
  components: {
    tooltipLancamento
  },
  name: 'OrganizzeFinanceiroCategorias',
  props: {
    lancamentoPorCategorias: {
      type: Object,
      default: () => {}
    },

    typeCategories: {
      type: String,
      required: true
    }
  },
  data: () => ({
    tooltipOpen: null,
    expanded:[],

    dessertHeaders: [
      { sortable: false, align: 'left', text: '', value: 'data-table-expand' },
      { sortable: false, width: 360, align: 'left', text: 'Categorias', value: 'categoria' },
      { sortable: false, width: 360, align: 'center', text: 'Percentual', value: 'percentual' },
      { sortable: false, width: 360, align: 'right', text: 'Valor', value: 'valor' },
    ],
    total: 0
  }),

  methods: {
    openMenuTooltipe (item) {
      this.tooltipOpen = item.data.id
    },
    getPercentage(totalLinha, totalGeral) {
      return (totalLinha / totalGeral) * 100
    },

    getPercentagePai(lancamento) {
      const data = this.lancamentoPorCategorias.data.find(item => item.lancamentos.categoriaPai.id === lancamento.data[0].categories.id)
      return (lancamento.total / data.total) * 100
    }
  },

  computed: {
    variables: () => variables,
    typeCategoriesString () {
      switch (this.typeCategories) {
        case 'D':
          return 'Despesas'
        case 'R':
          return 'Receitas'
        case 'C':
          return 'Custeio'
        default: 
          return 'Categorias'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/helpers/variables";
.table::v-deep {
    background: #FFF;
    .v-data-table__expanded__content {
      box-shadow: none;
      padding: 60px;
      background: #FFF;
    }

    tbody {
      tr {
        td:nth-child(1), th:nth-child(1) {
          width: 5%;
        }
        td:nth-child(2), th:nth-child(2) {
          width: 31.66%;
          text-align: left !important;

        }
        td:nth-child(3), th:nth-child(3) {
          width: 31.66%;
          text-align: center !important;
        }
        td:nth-child(4), th:nth-child(4) {
          width: 31.66%;
          text-align: right !important;
        }
      }
    }

    .table-premios-td {
      padding: 10px;
      .v-data-table__wrapper {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
      }
    }

    .color-red {
      color: $colorDanger;
    }

    .color-green {
      color: $colorSuccess;
    }

    .total-final {
      color: #62626c;
    }

    .description-final {
      color: #5076ff;
    }
  }

h1 {
  color: #4a5275;
  text-transform: none;
  font-size: 20px;
}

</style>