var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.typeCategoriesString)+" ")]),_c('v-data-table',{staticClass:"fb-table-elem table",attrs:{"headers":_vm.dessertHeaders,"items":_vm.lancamentoPorCategorias.data,"show-expand":"","hide-default-footer":"","disable-pagination":true},scopedSlots:_vm._u([{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lancamentos.categoriaPai.name)+" ")]}},{key:"item.percentual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.getPercentage(item.total, _vm.lancamentoPorCategorias.total)))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.total > 0 ? 'color-red' : 'color-green']},[_vm._v(_vm._s(_vm._f("currency")(item.total)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pr-0",attrs:{"colspan":headers.length}},[(item.lancamentos.raiz.children.length )?_c('v-data-table',{staticClass:"fb-table-elem pl-0 pr-0",attrs:{"headers":_vm.dessertHeaders,"items":item.lancamentos.raiz.children,"show-expand":"","single-expand":"","item-key":"k","hide-default-header":"","hide-default-footer":"","disable-pagination":true},scopedSlots:_vm._u([{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[0].data.RecordCategories.name)+" ")]}},{key:"item.percentual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.getPercentagePai(item)))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.total > 0 ? 'color-red' : 'color-green']},[_vm._v(_vm._s(_vm._f("currency")(item.total)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pr-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"fb-table-elem pl-0 pr-0",attrs:{"headers":_vm.dessertHeaders,"items":item.data,"hide-default-header":"","hide-default-footer":"","single-expand":"","item-key":_vm.getPercentagePai(item),"disable-pagination":true},scopedSlots:_vm._u([{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [_c('tooltipLancamento',{key:item.data.id,attrs:{"item":item,"id":item.data.id}})]}},{key:"item.percentual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.data.dtEmission,'DD/MM/YYYY'))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"total-final",class:[item.data.value > 0 ? 'color-red' : 'color-green']},[_vm._v(_vm._s(_vm._f("currency")(item.data.value)))])]}}],null,true)})],1)]}}],null,true)}):_vm._e(),(item.lancamentos.raiz.data.data.length)?_c('v-data-table',{staticClass:"fb-table-elem pl-0 pr-0",attrs:{"headers":_vm.dessertHeaders,"items":item.lancamentos.raiz.data.data,"hide-default-header":"","hide-default-footer":"","single-expand":"","disable-pagination":true},scopedSlots:_vm._u([{key:"item.categoria",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"description-final"},[_vm._v(_vm._s(item.data.description))])]}},{key:"item.percentual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.data.dtEmission,'DD/MM/YYYY'))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"total-final",class:[item.data.value < 0 ? 'color-red' : 'color-green']},[_vm._v(_vm._s(_vm._f("currency")(item.data.value)))])]}}],null,true)}):_vm._e()],1)]}}])},[_c('template',{slot:"body.append"},[_c('tr',{},[_c('th'),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("100%")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.lancamentoPorCategorias.total)))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }